const dev = process.env.NODE_ENV !== 'production';

//export const server = dev ? 'http://mortens-macbook-pro.local:3000' : '';
export const server = dev ? 'http://localhost:3000' : 'https://cherryblossom.no';

export const AFFILIATES_LINKS = {

};

export const AFFILIATE_LIST = [
    
];

export enum AFFILIATE {

}